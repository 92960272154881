<template>
  <div class="finance-account">
    <en-table-layout :tableData="tableData.data" :loading="loading">
      <template slot="toolbar">
        <div style="width: 100%;margin-top:10px">
          <el-form-item label="状态" class="col-auto">
            <el-select style="width: 100px" v-model="params.enable_status" size="small" placeholder="请选择" clearable>
              <el-option label="全部" value="" />
              <el-option label="启用中" :value="1" />
              <el-option label="已禁用" :value="0" />
            </el-select>
          </el-form-item>
          <el-form-item label="更新时间" class="col-auto">
            <el-date-picker style="width: 220px" v-model="advancedForm.order_time_range" type="daterange" align="center"
              size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
              end-placeholder="结束日期"></el-date-picker>
          </el-form-item>
          <el-form-item prop="name">
            <el-input style="width:220px" clearable v-model="params.name" placeholder="请输入自提点名称进行搜索"></el-input>
          </el-form-item>
          <el-form-item class="col-auto">
            <el-button @click="searchEvent" type="primary" size="small">搜索</el-button>
            <el-button @click="addNotice()" type="primary" size="small">添加门店</el-button>
          </el-form-item>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column label="自提点名称">
          <template slot-scope="{row}">{{ row.name }}</template>
        </el-table-column>
        <el-table-column label="地址" min-width="200">
          <template slot-scope="{row}">
            <span>{{ row.province + row.city + row.county + (row.town ? row.town : '') + row.addr }}</span>
          </template>
        </el-table-column>
        <el-table-column label="更新时间" width="220">
          <template slot-scope="{row}">{{ row.update_time | unixToDate }}</template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="{row}">
            <span>{{ row.enable_status ? '启用中' : '已禁用' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="240">
          <template slot-scope="{row}">
            <el-button v-if="row.enable_status" size="mini" type="danger"
              @click="editNotice(row, 'disabled')">禁用</el-button>
            <el-button v-if="!row.enable_status" size="mini" type="primary"
              @click="editNotice(row, 'open')">启用</el-button>
            <el-button v-if="!row.enable_status" size="mini" type="primary"
              @click="editNotice(row, 'edit')">编辑</el-button>
            <el-button v-if="!row.enable_status" size="mini" type="danger" @click="deletez(row)">删除</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :page-size="tableData.page_size" :total="tableData.data_total"></el-pagination>
    </en-table-layout>

    <!--修改|新增-->
    <el-dialog-x-dialog :title="loadingtitle" :visible.sync="editVisible">
      <el-form :model="txInfo" ref="txInfo" :rules="txRules" label-width="135px">
        <el-form-item label="门店名称" prop="name">
          <el-input placeholder="请输入门店名称" :maxlength="50" v-model="txInfo.name"></el-input>
        </el-form-item>
        <el-form-item label="商户地址" prop="province_id">
          <el-select @change="changeProvince('province')" size="small" class="choose-machine center"
            v-model="txInfo.province_id" placeholder="省/直辖市" style="width: 100px; margin-right: 5px" clearable>
            <el-option v-for="(item, index) in this.provinceList" :label="item.name" :value="item.id" :key="index">
            </el-option>
          </el-select>
          <el-select @change="changeProvince('city')" size="small" class="choose-machine center" v-model="txInfo.city_id"
            placeholder="城市/地区" style="width: 100px; margin-right: 5px" clearable>
            <el-option v-for="(item, index) in this.cityList" :label="item.name" :value="item.id" :key="index">
            </el-option>
          </el-select>
          <el-select @change="changeProvince('county')" size="small" class="choose-machine center"
            v-model="txInfo.county_id" placeholder="县/区" style="width: 100px; margin-right: 5px" clearable>
            <el-option v-for="(item, index) in this.countyList" :label="item.name" :value="item.id" :key="index">
            </el-option>
          </el-select>
          <el-select size="small" class="choose-machine center" v-model="txInfo.town_id" placeholder="乡镇/街道"
            style="width: 100px; margin-right: 5px" clearable>
            <el-option v-for="(item, index) in this.townList" :label="item.name" :value="item.id" :key="index">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="详细地址" prop="addr">
          <el-input placeholder="请输入详细地址" :maxlength="250" v-model="txInfo.addr"></el-input>
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="cancel" size="small">取 消</el-button>
          <el-button type="primary" size="small" @click="accountSubmit">确 定</el-button>
        </div>
      </el-form>
    </el-dialog-x-dialog>
  </div>
</template>

<script>
import XDialog from '@/components/x-dialog/x-dialog';
import * as API_goods from "@/api/goods";
import { pickupStationList, addPickupStation, editPickupStation, deletePickupStation, getPickupStation, editPickupState } from '@/api/shopSetting';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';
export default {
  name: 'addressManagement',
  components: { EnTableLayout, XDialog },
  data () {
    return {
      provinceList: [],
      cityList: [],
      countyList: [],
      townList: [],
      editVisible: false,//审核页
      loadingtitle: '',//弹窗标题
      /** 列表loading状态 */
      loading: false,
      txInfo: {//审核表单内容
        name: '',
        addr: '',
        province_id: "",
        city_id: "",
        county_id: "",
        town_id: ""
      },
      txRules: {//审核表单验证
        name: [
          { required: true, message: '请输入门店名称', trigger: 'blur' }],
        province_id: [
          { required: true, message: '请选择商户地址', trigger: 'change' }],
        addr: [
          { required: true, message: '请输入详细地址', trigger: 'change' }
        ],
      },
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20
      },
      /** 高级搜索数据 */
      advancedForm: {
        order_time_range: [],
      },
      /** 列表数据 */
      tableData: {},
    };
  },
  filters: {

  },
  mounted () {
    this.GET_AccountList();
  },
  methods: {
    changeProvince (type) {
      if (type == "province") {
        this.txInfo.city_id = "";
        this.txInfo.county_id = "";
        this.txInfo.town_id = "";
        if (this.txInfo.province_id === "") {
          this.getAreaHandle("province");
        } else {
          this.getAreaHandle("city", { id: this.txInfo.province_id });
        }
      } else if (type == "city") {
        this.txInfo.county_id = "";
        this.txInfo.town_id = "";
        if (this.txInfo.province_id === "") return;
        if (this.txInfo.city_id !== "") this.getAreaHandle("county", { id: this.txInfo.city_id });
      } else if (type == "county") {
        this.txInfo.town_id = "";
        if (this.txInfo.city_id === "") return;
        if (this.txInfo.county_id !== "") this.getAreaHandle("town", { id: this.txInfo.county_id });
      }
    },
    /** 搜索事件触发 */
    searchEvent () {
      this.params = {
        ...this.params,
        ...this.advancedForm
      };
      delete this.params.start_update_time;
      delete this.params.end_update_time;
      if (
        this.advancedForm.order_time_range &&
        this.advancedForm.order_time_range.length
      ) {
        if (typeof this.advancedForm.order_time_range[0] === "string") {
          this.params.start_update_time =
            new Date(this.advancedForm.order_time_range[0]).getTime() / 1000;
          this.params.end_update_time =
            new Date(this.advancedForm.order_time_range[1]).getTime() / 1000 +
            86400 - 1;
        } else {
          this.params.start_update_time =
            this.advancedForm.order_time_range[0].getTime() / 1000;
          this.params.end_update_time =
            this.advancedForm.order_time_range[1].getTime() / 1000 + 86400 - 1;
        }
      }
      delete this.params.order_time_range;
      this.params.page_no = 1;
      this.GET_AccountList();
    },
    /**
     * 获取区域数据
     * @param type
     * @param data
     */
    getAreaHandle (type, data = {}) {
      if (type === "province") {
        API_goods.getJDProvince().then((res) => {
          this.provinceList = this.dataHandle(res.data.result);
        });
      } else if (type === "city") {
        API_goods.getJDCity(data.id).then((res) => {
          this.cityList = this.dataHandle(res.data.result);
        });
      } else if (type === "county") {
        API_goods.getJDCounty(data.id).then((res) => {
          this.countyList = this.dataHandle(res.data.result);
        });
      } else if (type === "town") {
        API_goods.getJDTown(data.id).then((res) => {
          this.townList = this.dataHandle(res.data.result);
        });
      }
    },
    dataHandle (data) {
      let returnData = [];
      const keys = Object.keys(data);
      const values = Object.values(data);
      keys.forEach((key, index) => {
        const data = {
          name: key,
          id: values[index],
        };
        returnData.push(data);
      });
      return returnData;
    },
    /**关闭弹窗 */
    cancel () {
      this.editVisible = false
    },
    /**新增 */
    addNotice () {
      this.loadingtitle = "添加门店"
      this.txInfo = {//审核表单内容
        name: '',
        addr: '',
        province_id: "",
        city_id: "",
        county_id: "",
        town_id: ""
      }
      this.getAreaHandle("province");
      this.editVisible = true
    },
    /**打开弹窗 */
    async editNotice (row, type) {
      this.txInfo = await getPickupStation(row.id)
      switch (type) {//获取地址
        case "edit"://编辑
          this.loadingtitle = "编辑门店"
          this.getAreaHandle("province");
          if (this.txInfo.province_id) {
            this.getAreaHandle("city", { id: this.txInfo.province_id });
          }
          if (this.txInfo.city_id) {
            this.getAreaHandle("county", { id: this.txInfo.city_id });
          }
          if (this.txInfo.county_id) {
            this.getAreaHandle("town", { id: this.txInfo.county_id })
          }
          this.editVisible = true
          break;
        case "open"://启用  
          this.$confirm("确定启用该门店吗?", "提示", { type: "warning" }).then(() => {
            this.txInfo.enable_status = 1;
            editPickupState({ enable_status: 1 }, this.txInfo.id).then(res => {
              this.$message.success("启用成功");
              this.GET_AccountList();
            });
          });
          break;
        case "disabled": //禁用 
          this.$confirm("确定禁用该门店吗?", "提示", { type: "warning" }).then(() => {
            this.txInfo.enable_status = 0;
            editPickupState({ enable_status: 0 }, this.txInfo.id).then(res => {
              this.$message.success("禁用成功");
              this.GET_AccountList();
            });
          });
          break;
        default:

          break;
      }

    },
    /**提交 */
    accountSubmit () {
      this.$refs.txInfo.validate(valid => {
        let datas = {
          ...this.txInfo
        }

        if (valid) {
          if (datas.province_id !== "") {
            const findProvince = this.provinceList.find(item => item.id === datas.province_id)
            datas.province = findProvince.name;
            if (datas.city_id !== "") {
              const findCity = this.cityList.find(item => item.id === datas.city_id)
              datas.city = findCity.name;

            }
            if (datas.county_id !== "") {
              const findCointy = this.countyList.find(item => item.id === datas.county_id)
              datas.county = findCointy.name;

            }
            if (datas.town_id !== "" && datas.town_id !== null) {
              const findTown = this.townList.find(item => item.id === datas.town_id)
              datas.town = findTown.name;

            }
          }
          if (this.txInfo.id) {//编辑         
            editPickupStation(datas).then((response) => {
              this.$message.success('门店修改成功！');
              this.editVisible = false;
              this.GET_AccountList();
            })
          } else {//添加
            addPickupStation(datas).then((response) => {
              this.$message.success('门店添加成功！');
              this.editVisible = false;
              this.GET_AccountList();
            })
          }
        }
      });
    },
    deletez (row) {//删除
      this.$confirm("确定删除该门店吗?", "提示", { type: "warning" }).then(() => {
        deletePickupStation(row.id).then(res => {
          this.$message.success("删除成功");
          this.GET_AccountList();
        });
      });
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_AccountList();
    },
    GET_AccountList () {
      this.loading = true;
      let params = this.params;
      pickupStationList(params).then(response => {
        this.loading = false;
        this.tableData = response;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.finance-account-table-header {
  padding: 16px 16px 0;

  .line {
    font-size: 13px;
    color: #666;
  }
}

/deep/ .el-radio-group {
  .el-radio {
    margin-bottom: 0 !important;
  }
}

.en-table-layout .en-table-layout-toolbar .el-form-item {
  margin-bottom: 8px;
}

/deep/ .el-scrollbar__wrap {
  margin-right: -7px !important;
}

.dialog-footer {
  text-align: center;
  margin: 20px 0;
}
</style>
